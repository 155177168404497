import React from 'react'
import Social from './social'
import Nav from './nav'
import { rhythm } from '../../utils/typography'
import colors from '../../utils/colors'

const NavWrapper = props => (
    <div
        style={{
            display: `flex`,
            position: `fixed`,
            backgroundColor: `${colors.textPrimaryColor}`,
            justifyContent: `space-between`,
            alignItems: `stretch`,
            borderBottom: `1.5px solid rgba(121, 85, 72, 0.09)`,
            zIndex: `99`,
            width: `100%`,
            height: `${rhythm(2)}`,
        }}
    >
        <Nav links={props.links} sections={props.sections} />
        <Social social_links={props.social} />
    </div>
)

export default NavWrapper
