const colors = {
    accentColorSecondary: '#ffe3b3',
    borderBottom: `1.5px solid rgba(121, 85, 72, 0.09)`,
    sidebarBackground: `rgba(255, 246, 230, 0.81)`,
    accentColorPrimary: '#ffd180',
    lightPrimaryColor: '#D7CCC8',
    darkPrimaryColor: '#5D4037',
    defaultPrimary: '#795548',
    lightPrimaryColor: '#D7CCC8',
    textPrimaryColor: '#FFFFFF',
    primaryTextColor: '#212121',
    secondaryTextColor: '#757575',
    dividerColor: '#BDBDBD'

}

export default colors