import React from 'react'
import NavWrapper from './nav/nav-wrapper'
import SideNav from './nav/side-nav'
import docsSidebarYaml from '../pages/nav-links-sections.yaml'
import Container from './container'
import 'bootstrap/dist/css/bootstrap.min.css'
import { StaticQuery, graphql } from 'gatsby'
import { StyleRoot } from 'radium'

class Layout extends React.Component {

    render() {
        const isHomepage = this.props.location.pathname === `/`
        const isThankYou = this.props.location.pathname === `/thanks/`
        const showSideBar = !isHomepage && !isThankYou

        return (
            <StyleRoot css={{ height: `100%` }}>
                <StaticQuery query={
                    graphql`
        query Layout {
          datoCmsIndexPage {
            primaryNavigation
              socialLinks {
                link
                icon {
                  url
                }
              }
            }
          }
      `}
                    render={(data) =>
                        (
                            <Container css={
                                {
                                    display: `flex`,
                                    flexDirection: `column`,
                                    margin: `0 auto`,
                                    width: `100%`,
                                    height: `100%`
                                }
                            } >
                                <NavWrapper links={
                                    data.datoCmsIndexPage.primaryNavigation
                                }
                                    social={
                                        data.datoCmsIndexPage.socialLinks

                                    }
                                    sections={
                                        docsSidebarYaml
                                    }

                                />
                                <Container css>
                                    {showSideBar && <SideNav yaml={docsSidebarYaml} />}
                                    {this.props.children}
                                </Container>
                            </ Container>
                        )
                    }
                />
            </StyleRoot>
        )

    }
}


export default Layout