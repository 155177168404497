import React from 'react'

const Container = props => {
    return (

        <div
            {...props}
            css={{
                display: `flex`,
                height: `100%`,
            }}
        >
            {props.children}
        </div>
    )
}
export default Container
