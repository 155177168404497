import React from 'react'
import Link from 'gatsby-link'
import colors from '../../utils/colors'
import { scale, rhythm } from '../../utils/typography'

class StyledLink extends React.Component {

    render() {

        const styles = {
            primaryNav: {
                color: colors.darkPrimaryColor,
                textShadow: 'none',
                marginLeft: `${rhythm(1 / 2)}`,
                display: `flex`,
                alignItems: `center`,
                textDecoration: `none`,
                alignSelf: 'stretch',
                ':hover': {
                    color: `${colors.defaultPrimary}`,
                    textDecoration: `none`
                },
                '.active': {
                    backgroundImage: 'linear-gradient(to right, #795548, #795548)',
                    backgroundRepeat: 'repeat-x',
                    backgroundPosition: 'bottom',
                    backgroundSize: '80% 10%',
                    color: `${colors.darkPrimaryColor}`,
                }
            },
            social: {
                margin: '0px 5px',
                display: `flex`,
                alignItems: `center`,
                alignSelf: `stretch`,
                color: colors.darkPrimaryColor,
                backgroundImage: 'none',
                ':hover': {
                    color: `${colors.defaultPrimary}`,
                }
            },
            sideNav: {
                color: colors.darkPrimaryColor,
                textDecoration: `none`,
                textShadow: 'none',
                marginLeft: `${rhythm(1 / 16)}`,
                display: `flex`,
                alignItems: `center`,
                alignSelf: 'stretch',
                background: `none`,
                ':hover': {
                    color: `${colors.defaultPrimary}`,
                    textDecoration: `none`,
                },
                '.active': {
                    color: `${colors.defaultPrimary}`,
                    textDecoration: `none`,
                }
            }
        }

        const internal = /^\/(?!\/)/.test(this.props.to)
        if (internal){
            return (
                <Link activeClassName="active" to={this.props.to} css={styles[this.props.kind]}>
                    {this.props.children}
                </Link>
            );
        }
        else {
            return (
                <a href={this.props.to} style={styles[this.props.kind]} target='_blank'>
                    {this.props.children}
                </a>
            )
        }
    }
}

export default StyledLink
