import React from 'react'
import NavLogo from './logo'
import StyledLink from './styled-link'
import colors from '../../utils/colors'
import { rhythm } from '../../utils/typography';


const NavSeparator = (props) => {
    var styles = {
        navSeparator: {
            display: 'flex',
            width: `${rhythm(1 / 5)}`,
            height: `${rhythm(1 / 5)}`,
            margin: '0rem 1rem',
            borderRadius: '50%',
            alignSelf: 'center',
            background: colors.darkPrimaryColor,
        },
    }
    return <span style={styles.navSeparator} />
}

class Nav extends React.Component {

    render() {
        const styles = {
            display: 'flex',

        }
        const sections = this.props.sections
        return (
            <div style={styles} >
                <div style={styles}>
                    <NavLogo to="/"></NavLogo>
                </div>
                <div css={{ display: `flex` }}>
                    {
                        sections.map((section, index, arr) => {
                            if ((index + 1) < arr.length) {
                                return (
                                    <div key={index} css={{ display: `flex` }}>
                                        <StyledLink to={section.items[0].link} kind='primaryNav'>
                                            {section.title}
                                        </StyledLink>
                                        <NavSeparator />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={index} css={{ display: `flex` }}>
                                        <StyledLink key={index} to={section.items[0].link} kind='primaryNav'>
                                            {section.title}
                                        </StyledLink>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Nav
