import React from 'react'
import {
    Link
} from '@reach/router'
import logo from '../../assets/img/cent-icon-gold.svg'
import Container from '../container';

const styles = {
    logo: {
        margin: '10px',
        width: '40px',
        height: '40px',
        transition: 'all 2s',
        ':hover': {
            transform: 'rotate(360deg)'
        }
    }
}

class NavLogo extends React.Component {

    render() {
        return (
            <Container css={{ display: `flex`, alignItems: `center` }}>
                <Link to={this.props.to}>
                    <img css={styles.logo} src={logo} alt="citywide-logo"></img>
                </Link>
            </Container>
        )
    }
}

export default NavLogo
