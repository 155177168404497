import React from 'react'
import { rhythm, scale, options } from '../../utils/typography'
import StyledLink from './styled-link'
import colors from '../../utils/colors'
import presets from '../../utils/presets'


const Section = props => (
    <div>
        <h3>
            {props.title}
        </h3>
        <SectionLinks
            {...props}
            title={props.title}
        />
    </div>
);

const SectionLinks = props => {
    const listStyles = {
        listStyle: `none`,
    }
    return (
        <ul
            css={{
                ...listStyles,
                // For nested <ul>s in the "Tutorial" section
                "& ul": {
                    ...listStyles,
                },
            }}
        >
            {props.items.map((item, index) => (
                <SectionLink
                    node={item}
                    children={item.items}
                    key={index}
                    isInline={props.isInline}
                    isTutorial={props.isTutorial}
                />
            ))}
        </ul>
    )
}

const SectionLink = props => {
    // Don't show the main docs link on mobile as we put these
    // links on that main docs page so it's confusing to have
    // the page link to itself.
    let childnodes = null
    if (props.children) {
        childnodes = props.children.map((childnode, index) => (
            <SectionLink
                key={index}
                node={childnode}
                children={childnode.items}
                isNested={true}
                isTutorial={props.isTutorial}
            />
        ))
    }
    const item = props.node

    // If the last character is a * then the doc page is still in draft
    const isDraft = item.title.slice(-1) === `*`
    const title = isDraft ? item.title.slice(0, -1) : item.title
    const isTutorialFirstLevel = props.isTutorial && !props.isNested

    return (
        <li key={item.title} >
            {item.link.charAt(0) == `#` ? (
                <a href={item.link} >
                    {title}
                </a>
            ) : (
                    <StyledLink
                        to={item.link}
                        kind="sideNav"
                        activeClassName="nav-link-active"
                        className="nav-link"
                        exact
                    >
                        {title}
                    </StyledLink>
                )}
            {childnodes ? <ul>{childnodes}</ul> : null}
        </li>
    )
}


class SideNav extends React.Component {

    render() {
        const menu = this.props.yaml
        return (
            <div
                css={{
                    display: `flex`,
                    height: `calc(100vh - ${rhythm(2)})`,
                    backgroundColor: colors.sidebarBackground,
                    padding: `${rhythm(1)}`,
                    marginTop: `${rhythm(2)}`,
                    overflowY: `scroll`,
                    flex: `1 0 20%`,
                    flexDirection: `column`,
                    position: `fixed`,
                    [presets.maxTablet]: {
                        display: `none`
                    },
                    "::-webkit-scrollbar": {
                        width: `${rhythm(1 / 5)}`,
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: colors.accentColorPrimary
                    },
                    "::-webkit-scrollbar-track": {
                        background: colors.accentColorSecondary
                    },
                }}>
                {menu.map((section, index) => (
                    <div
                        key={index}
                    >
                        <Section
                            {...section}
                            title={section.title}
                            index={index}
                        />
                    </div>
                ))}
            </div>
        )
    }
}

export default SideNav