import React from 'react'
import StyledLink from './styled-link'
import Radium from 'radium'
import facebookIcon from '../../assets/img/fb_icon.svg'
import instagramIcon from '../../assets/img/insta_icon.svg'
import wranglerLogo from '../../assets/img/wrangler_logo.svg'


const styles = {
  width: `3rem`,
  height: `3rem`,
  margin: `0`,
  padding: `0.5rem`,
  textDecoration: `none`
}

const Social = (props) => (
  <div style= {{display: `flex`}}>
    <StyledLink to="/contact/" kind='primaryNav'>Contact Us</StyledLink>
    {
      props.social_links.map(function(social_link, i) {
        return (
          <a key={i} href={social_link.link}>
            <img style={styles} src={social_link.icon.url}>
            </img>
          </a>
        )
      })
    }
  </div>
);


export default Social
