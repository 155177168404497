import Typography from 'typography'
import github from 'typography-theme-github'

github.baseFontSize = '16px'

github.overrideThemeStyles = ({
    rhythm
}, options) => ({
    'a': {
        backgroundImage: 'none',
        textDecoration: 'none',
        textShadow: 'none',
    }
})

const typography = new Typography(github)

export const {
    scale,
    rhythm,
    options
} = typography
export default typography